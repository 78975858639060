import { getAppMetadata } from './metadata';
import { Insight, Environment, Poi, } from '@workspaces/types';
export const getGenericRegionFromRegion = (regionId) => {
    const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy;
    let genericRegionsKey;
    for (genericRegionsKey in genericRegions) {
        if (genericRegions[genericRegionsKey]?.id === regionId) {
            return genericRegionsKey;
        }
    }
    throw Error(`Trying to get generic region for value ${regionId}. Check metadata file to verify the value is correct`);
};
export const getRegionFromGenericRegion = (region) => {
    const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy;
    let genericRegionsKey;
    for (genericRegionsKey in genericRegions) {
        if (genericRegionsKey.toLocaleLowerCase() === region.toLocaleLowerCase()) {
            const regionValue = genericRegions[genericRegionsKey];
            if (regionValue === undefined) {
                throw Error(`Trying to get region value for generic region ${region}. Check metadata file to verify the value is correct`);
            }
            return regionValue;
        }
    }
    throw Error(`Trying to get region value for generic region ${region}. Check metadata file to verify the value is correct`);
};
export const getRegionsTypeId = () => {
    const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy;
    let genericRegionsKey;
    const regions = [];
    for (genericRegionsKey in genericRegions) {
        const regionValue = genericRegions[genericRegionsKey];
        if (regionValue === undefined) {
            throw Error(`Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`);
        }
        regions.push(String(regionValue.id));
    }
    return regions;
};
export const getRegionsGenericNameFromTypeId = (typeId) => {
    const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy;
    let genericRegionsKey;
    for (genericRegionsKey in genericRegions) {
        const regionValue = genericRegions[genericRegionsKey];
        if (regionValue === undefined) {
            throw Error(`Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`);
        }
        if (regionValue.id === typeId) {
            return genericRegionsKey;
        }
    }
    throw Error(`Not found region name formatted for type_id value ${typeId}. Check metadata file to verify the value is correct`);
};
export const getRegionsTypeIdOrdered = () => {
    const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy;
    let genericRegionsKey;
    const regions = [];
    for (genericRegionsKey in genericRegions) {
        const regionValue = genericRegions[genericRegionsKey];
        if (regionValue === undefined) {
            throw Error(`Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`);
        }
        regions.push(regionValue);
    }
    const regionsOrdered = regions.sort((a, b) => a.order - b.order);
    const regionsOrderedTypeId = regionsOrdered.map((region) => region.id);
    return regionsOrderedTypeId;
};
export function showExcludedRangeBufferForPOIs(meta) {
    return meta.plan_filters.widget_proximity.types.radius.double_buffer;
}
export function getBqProject(meta, environment) {
    const env = environment.getEnvironment();
    const bqProject = meta.bq_project;
    switch (env) {
        case Environment.Environment.Development:
            return bqProject.dev;
        case Environment.Environment.Staging:
            return bqProject.staging;
        case Environment.Environment.Production:
            return bqProject.prod;
    }
    throw new Error(`Could not find BQ project for environment ${env}. Check metadata file to verify values are correctly set under bq_project parameter`);
}
export function getTilesetFullNameFor(meta, environment, tileset) {
    const env = environment.getEnvironment();
    const isTesting = environment.isTesting();
    const project = getBqProject(meta, environment);
    const dataset = isTesting ? `${env}_testing` : env;
    const tilesetFullName = `${project}.${dataset}.${tileset}`;
    return tilesetFullName;
}
export function getConnection(meta, environment) {
    const env = environment.getEnvironment();
    return `${meta.connection}_${env}`;
}
export function isDemographicsTabEnabled(meta) {
    return (meta.features.insights.enable && meta.features.insights.tabs.demographics);
}
export function isImpressionsTabEnabled(meta) {
    return (meta.features.insights.enable && meta.features.insights.tabs.impressions);
}
export function isOverviewTabEnabled(meta) {
    return meta.features.insights.enable && meta.features.insights.tabs.table_view;
}
export function isTopIndexTabEnabled(meta) {
    return (meta.features.insights.enable && meta.features.insights.tabs.top_indexing);
}
export function getMaxCustomPOIsPerFile(meta, isAdmin) {
    if (meta.features.manage_pois.distinguish_between_user_and_admin) {
        return isAdmin
            ? meta.features.manage_pois.max_custom_pois_per_file_admin_user
            : meta.features.manage_pois.max_custom_pois_per_file;
    }
    return meta.features.manage_pois.max_custom_pois_per_file;
}
export function getMaxCustomPOIsByGeocoding(meta, isAdmin) {
    if (meta.features.manage_pois.distinguish_between_user_and_admin) {
        return isAdmin
            ? meta.features.manage_pois.max_custom_pois_geocoding_admin_user
            : meta.features.manage_pois.max_custom_pois_geocoding;
    }
    return meta.features.manage_pois.max_custom_pois_geocoding;
}
export function getMapClusterFixedSize(meta) {
    return meta.features.map.fixed_cluster_size;
}
export function getMaxZipCodesperFile(meta) {
    return meta.features.manage_pois.max_custom_pois_per_file;
}
export function getSriteIndexForLegendIcon(index) {
    const meta = getAppMetadata();
    let secureIndex = index;
    if (index >= meta.features.map.legend.colors.length) {
        secureIndex = index % meta.features.map.legend.colors.length;
    }
    return meta.features.map.legend.colors[secureIndex];
}
export function enableSelectingByProductInLegend(meta) {
    return meta.features.map.legend.enable_product_selection;
}
export function defaultProductSelectionInLegend(meta) {
    return meta.features.map.legend.default_product_selection;
}
export function getIdentifiersForGenderCharts() {
    const meta = getAppMetadata();
    return meta.features.insights.features.demographics.identify_gender_charts;
}
export function needToPrefilterDemographicsData() {
    const meta = getAppMetadata();
    return meta.features.insights.features.demographics
        .pre_filter_audiences_classes;
}
export function needToSortDataByValuesInDemographicsData() {
    const meta = getAppMetadata();
    return meta.features.insights.features.demographics.sort_by_values;
}
export function getSimpliestChartForDemographicsInsigths() {
    const meta = getAppMetadata();
    return meta.features.insights.features.demographics.simpliest_chart;
}
export function getTemplatetForDemographicsInsigths() {
    const meta = getAppMetadata();
    return meta.features.insights.features.demographics.template;
}
export function isAuditEnabled(meta) {
    return meta.features.audit.enable;
}
export function getCartoAppConfiguration(meta, environment) {
    const env = environment.getEnvironment();
    const cartoOrganization = meta.app_config.carto_organization;
    switch (env) {
        case Environment.Environment.Development:
            return {
                clientId: meta.app_config.carto_client_id.dev,
                organization: cartoOrganization,
            };
        case Environment.Environment.Staging:
            return {
                clientId: meta.app_config.carto_client_id.staging,
                organization: cartoOrganization,
            };
        case Environment.Environment.Production:
            return {
                clientId: meta.app_config.carto_client_id.prod,
                organization: cartoOrganization,
            };
        default:
            throw Error(`Environment ${env} not found. Check .env file to verify VUE_APP_ENV is set.`);
    }
}
export function isMultiCountry(meta) {
    return meta.countries.length > 1;
}
export function getCountryISO2ForNotMultiCountry(meta) {
    return meta.countries[0].iso2;
}
export function getCountriesIdsFromMetadata(meta) {
    return meta.countries.map((country) => country.id);
}
export function isEnableForCurrentEnvironment(enableByEnvironment, environment) {
    const env = environment.getEnvironment();
    switch (env) {
        case Environment.Environment.Development:
            return enableByEnvironment.dev;
        case Environment.Environment.Staging:
            return enableByEnvironment.staging;
        case Environment.Environment.Production:
            return enableByEnvironment.prod;
        default:
            console.error(`🛑 Unrecognized environment value: ${env} . Check .env file to verify VUE_APP_ENV is set.`);
            return false;
    }
}
export function getAudienceFeatureVersion() {
    return getAppMetadata().plan_filters.widget_audiences.version;
}
export function getProximityFeatureVersion() {
    return getAppMetadata().plan_filters.widget_proximity.version;
}
export function getMediaTypeFeatureVersion() {
    const metaData = getAppMetadata();
    const version = metaData.plan_filters.widget_media_type.version;
    return version;
}
export function isTotalAssetsColumnIncludedInExport(meta) {
    return meta.features.export.include_total_assets_column;
}
export function getDefaultMethodForUploadingAssets(meta) {
    return meta.plan_filters.widget_assets.default_option;
}
export function showMethodFileForUploadingAssets(meta) {
    return meta.plan_filters.widget_assets.upload_by_file;
}
export function showMethodPasteForUploadingAssets(meta) {
    return meta.plan_filters.widget_assets.upload_by_paste;
}
export function getExportFileColumns(meta) {
    return meta.features.export.fileColumnTitles;
}
export function isExcludedFeatureForGeoboundary(meta) {
    return meta.plan_filters.widget_geoboundaries.permit_exclude;
}
export function getMediaTypeNameForCountry(meta, key, countryId) {
    const countryInfo = meta.countries.find((country) => country.id === countryId);
    if (countryInfo && countryInfo.meta_assets) {
        return (countryInfo.meta_assets.find((t) => t.type === key || t.type.startsWith(key))?.name || null);
    }
    return null;
}
export function getMediaTypeVisibilityForCountry(meta, countryId) {
    const countryInfo = meta.countries.find((country) => country.id === countryId);
    if (countryInfo && countryInfo.meta_assets) {
        return countryInfo.meta_assets.filter((t) => !t.hide).map((t) => t.type);
    }
    throw new Error(`No meta_assets found for country ${countryId}. Check metadata content`);
}
export function getMediaTypeVisibilityForCountryAndLevel(meta, key, countryId) {
    const countryInfo = meta.countries.find((country) => country.id === countryId);
    if (countryInfo && countryInfo.meta_assets) {
        const hide = countryInfo.meta_assets.find((t) => t.type === key || t.type.startsWith(key))?.hide || false;
        return !hide;
    }
    return true;
}
export function isExcludedAssetsLegenedEnabled(meta) {
    return meta.features.map.legend.exclude_assets;
}
export function uploadInventoryPermitExcludedAssets(meta) {
    return meta.plan_filters.widget_assets.upload_inventory_permit_excluded;
}
export function isSaveAsEnabled(meta) {
    return (meta.features.save_plan.enable && meta.features.save_plan.enable_save_as);
}
export function isSharePlanEnabled(meta, environment) {
    const setupByEnvironment = meta.features.share.enable;
    const isEnabled = isEnableForCurrentEnvironment(setupByEnvironment, environment);
    return isEnabled;
}
export function showAssetDetailOverMap(meta) {
    return meta.features.map.asset_details_over_map;
}
export function getZoomLevelForDeclustering(meta) {
    return meta.features.map.zoom_hide_cluster;
}
export function getFormatFunctionForDemographicsInsights(meta, property) {
    const formatFunction = meta.features.insights.features.demographics.format;
    switch (property) {
        case Insight.InsightProperties.Index:
            return formatFunction.index;
        case Insight.InsightProperties.Market:
            return formatFunction.market;
        case Insight.InsightProperties.Panel:
            return formatFunction.panel;
        default:
            throw Error(`Property ${property} not found for selecting function formatter for demographics insights. Check metadata file under property:features.insights.features.demographics.format, to verify the value is correct`);
    }
}
export function getAssetsBlockSize(meta) {
    return meta.features.share.retrieve_in_blocks_of;
}
export function showButtonsTooltipAsCopies(meta) {
    return meta.features.widgets.show_button_tooltip_as_copies;
}
export function getCacheInforForModel(model) {
    const cache = model.cache;
    if (!cache) {
        throw Error(`Cache information not found for model ${model.table_name}. Check metadata file to verify the value is correct`);
    }
    return cache;
}
export function showFacingDirectionInLegend(meta) {
    return meta.features.map.legend.facing_direction;
}
export function showAngleMapNorthInLegend(meta) {
    return meta.features.map.legend.angle_to_map_north;
}
export function showBufferVisibilityControl(meta) {
    return meta.features.map.legend.show_buffers_visibility_control;
}
export function getChunkSizeForDownloadingAssets(meta) {
    return meta.data_model.assets.chunk_size ?? 50000;
}
export function isExcludeAssetsFeatureEnabled(meta) {
    return meta.features.assets.exclude;
}
export function switchToListViewFeatureAvailable(meta) {
    return meta.plan_filters.widget_list_assets.switch_to_list_view;
}
export function getCurrency(meta) {
    return meta.units.currency;
}
export function getCurrencySymbol(meta) {
    return meta.units.currency_symbol;
}
export function isPackagagesFeatureEnabled(meta) {
    return meta.features.packages.enable;
}
export function skipPopupAndGoToSidebar(meta) {
    return meta.features.assets.skip_popup_and_go_to_sidebar ?? false;
}
export function showAssetsSidebarWhenDoubleClickOnAssetList(meta) {
    return meta.features.assets.show_sidebar_when_double_click_on_list ?? false;
}
export function getColorForAvailability(meta, value) {
    const availabilityRange = meta.features.packages.availability_range;
    for (const elementInRange of availabilityRange) {
        if (elementInRange.value <= value) {
            return elementInRange.color;
        }
    }
    return availabilityRange[availabilityRange.length - 1].color;
}
export function getDescriptionForAvailability(meta, value) {
    const availabilityRange = meta.features.packages.availability_range;
    for (const elementInRange of availabilityRange) {
        if (elementInRange.value <= value) {
            return elementInRange.description;
        }
    }
    return availabilityRange[availabilityRange.length - 1].color;
}
export function getTextForAvailability(meta, value) {
    const availabilityRange = meta.features.packages.availability_range;
    for (const elementInRange of availabilityRange) {
        if (elementInRange.value <= value) {
            return elementInRange.text;
        }
    }
    return availabilityRange[availabilityRange.length - 1].text;
}
export function getColorForState(meta, value) {
    const color = meta.features.packages.states_style[value];
    if (!color) {
        throw Error(`Color not found for state ${value}. Check metadata file under key: states_style to verify the value is correct`);
    }
    return color;
}
export function getDashboardColumnsFor(dashboardColumns, modelAccseors) {
    if (modelAccseors) {
        return dashboardColumns.model_accessors;
    }
    return dashboardColumns.locale_keys;
}
export function getExportColumnsIndexToAvoidApplyingNumberFormat(meta) {
    const exportColumns = meta.features.export.fields_mapping;
    // get the index of the columns that has property avoidApplyingNumberFormat set to true
    const columnsToDoNotApplyNumberFormat = exportColumns
        .map((column, index) => {
        if (column.avoidApplyingNumberFormat) {
            return index;
        }
        return -1;
    })
        .filter((index) => index !== -1);
    return columnsToDoNotApplyNumberFormat;
}
export function isManagePOIsEnabled(meta) {
    return meta.features.manage_pois.enable;
}
export function isPOIsEnabled(meta) {
    return meta.plan_filters.widget_proximity.enable;
}
export function getPermissionResolverVersion(meta) {
    return meta.features.permission_resolver;
}
export function isCustomGeoboundaryDatasetEnabled(meta) {
    return meta.features.custom_geoboundaries.enable;
}
export function isPOIsMulticolorEnabled(meta) {
    return meta.features.proximity.multicolor;
}
export function getPOIsDefaultColor(meta) {
    return meta.features.proximity.default_color;
}
export function getPOIsColors(meta) {
    return {
        ramp: meta.features.proximity.ramp_color,
        others: meta.features.proximity.others_color,
    };
}
export function getPackageExpireConfirmationTime(meta) {
    return meta.features.packages.expiration.booking_confirmation;
}
export function getPackageExpireCancelTime(meta) {
    return meta.features.packages.expiration.cancellation_time_limit;
}
export function getISO2Flavour(meta) {
    return meta.flavour;
}
export function getBucketForAttachments(meta, environment) {
    const env = environment.getEnvironment();
    if (!meta.app_config.buckets) {
        throw new Error('Buckets setup not found in metadata file. Check app_config in metadata file');
    }
    if (!meta.app_config.buckets.attachments) {
        throw new Error('Attachments bucket setup not found in metadata file. Check app_config in metadata file');
    }
    const bucketsByEnvironment = meta.app_config.buckets.attachments;
    switch (env) {
        case 'dev':
            return bucketsByEnvironment.dev;
        case 'stg':
            return bucketsByEnvironment.staging;
        case 'pro':
            return bucketsByEnvironment.prod;
        default:
            throw Error(`🛑 Getting remote function. Unrecognized environment value: ${env} . Check .env file to verify VUE_APP_ENV is set.`);
    }
}
export function getCommentAttachmentsMaxSizeInMB(meta) {
    return meta.features.comments.attachments.max_size_mb;
}
export function getAreAtttachmentsEnabled(meta) {
    return (isCommentsFunctionalityEnabled(meta) &&
        meta.features.comments.attachments.enable);
}
export function getTermsOfUseLink(meta) {
    return meta.terms_of_use_url;
}
export function getWelcomeMessage(meta) {
    return meta.login_page_setup?.welcome_message;
}
export function getContactMessage(meta) {
    if (!meta.login_page_setup?.contact_message) {
        return undefined;
    }
    if (meta.login_page_setup?.contact_message &&
        meta.login_page_setup?.contact_email) {
        return {
            message: meta.login_page_setup.contact_message,
            email: meta.login_page_setup.contact_email,
        };
    }
    throw new Error('Contact message is enabled but email is not set');
}
export function showTermsOfUseAtLogin(meta) {
    return meta.login_page_setup?.show_terms_of_use ?? false;
}
export function getSupportedCountries(meta) {
    return meta.countries.map((country) => country.iso2);
}
export function getMandatoryHeaderForCustomPOIsTemplate(meta, type) {
    switch (type) {
        case Poi.CustomPOIsImportMode.Coordinates:
            return meta.features.manage_pois.xlsx_template_coords
                .mandatory_fields_in_template;
        case Poi.CustomPOIsImportMode.Geocode:
            return meta.features.manage_pois.xlsx_template_geocode
                .mandatory_fields_in_template;
    }
    throw new Error(`Mandatory fields not found for type ${type}. Check metadata file to verify the value is correct`);
}
export function getMandatoryFiledsWithDataForCustomPOIsTemplate(meta, type) {
    switch (type) {
        case Poi.CustomPOIsImportMode.Coordinates:
            return meta.features.manage_pois.xlsx_template_coords
                .mandatory_fields_with_data;
        case Poi.CustomPOIsImportMode.Geocode:
            return meta.features.manage_pois.xlsx_template_geocode
                .mandatory_fields_with_data;
    }
    throw new Error(`Mandatory fields not found for type ${type}. Check metadata file to verify the value is correct`);
}
export function getFileNameForCustomPOIsTemplate(meta, type) {
    switch (type) {
        case Poi.CustomPOIsImportMode.Coordinates:
            return meta.features.manage_pois.xlsx_template_coords.template;
        case Poi.CustomPOIsImportMode.Geocode:
            return meta.features.manage_pois.xlsx_template_geocode.template;
    }
    throw new Error(`File name not found for type ${type}. Check metadata file to verify the value is correct`);
}
export function getFieldsWithAtLeastOneFullfilledForCustomPOIsTemplate(meta, type) {
    switch (type) {
        case Poi.CustomPOIsImportMode.Coordinates:
            return [];
        case Poi.CustomPOIsImportMode.Geocode: {
            const res = meta.features.manage_pois.xlsx_template_geocode
                .at_least_one_field_with_data_within;
            if (!res) {
                throw new Error(`Fields not defined for type ${type}. Check metadata file property "xlsx_template_geocode.at_least_one_field_with_data_within" to verify the value is correct`);
            }
            return res;
        }
    }
    throw new Error(`Fields not found for type ${type}. Check metadata file to verify the value is correct`);
}
export function getOptionalFieldsForCustomPOIsTemplate(meta, type) {
    switch (type) {
        case Poi.CustomPOIsImportMode.Coordinates: {
            const res = meta.features.manage_pois.xlsx_template_coords.optional_fields;
            if (!res) {
                throw new Error(`Optional fields not defined for type ${type}. Check metadata file property "xlsx_template_coords.optional_fields" to verify the value is correct`);
            }
            return res;
        }
        case Poi.CustomPOIsImportMode.Geocode:
            return [];
    }
    throw new Error(`Optional fields not found for type ${type}. Check metadata file to verify the value is correct`);
}
export function getFieldsForCustomPOIsDownloadTemplate(meta) {
    return meta.features.manage_pois.xlsx_header_download;
}
export function showPackagePriceInSharePlan(meta) {
    const packageProps = meta.features.share.package;
    if (packageProps === undefined) {
        return false;
    }
    return packageProps.showPrice;
}
export function getBasemapsConfig(meta) {
    const basemapConfig = meta.app_config.basemaps;
    if (!basemapConfig.enable) {
        return null;
    }
    return basemapConfig;
}
export function getLayersPrecedence(meta) {
    return meta.features.map.layers_precedence;
}
export function isAudiencesQuintileEnabled(meta) {
    if (!meta.features.audiences_quintile) {
        return false;
    }
    return meta.features.audiences_quintile.enabled;
}
export function isFeatureLayerHeatMapEnabled(meta) {
    return meta.features.map.legend.show_heatmap_layer;
}
export function getCountryNameFromId(meta, countryId) {
    const country = meta.countries.find((c) => c.id === countryId);
    if (!country) {
        throw new Error(`Country with id ${countryId} not found. Check metadata file to verify the value is correct`);
    }
    return country.name;
}
export function isIntelligentMediaTypeDigitalStaticEnabled(meta) {
    const widget = meta.plan_filters.widget_media_type;
    return (widget.enable &&
        widget.filter_digital &&
        widget.intelligent_digital_static_filter);
}
export function isclearActionInPanelTypeWidgetActingUponDigitalFilter(meta) {
    return meta.plan_filters.widget_media_type.clear_action_acts_on_digital_filter;
}
export function getMaxAssetsPerPoi(meta) {
    const value = meta.features.proximity.max_assets_per_poi;
    if (value === undefined) {
        throw new Error('Max assets per POI not found. Check metadata parameter max_assets_per_poi to verify the value is set');
    }
    return value;
}
export function isQuickAccessToPlanVisibilityEnabled(meta) {
    return meta.features.plan.enable_quick_access_to_visibility;
}
export function isCommentsFunctionalityEnabled(meta) {
    return meta.features.comments.enable;
}
export function isQuickAccessCommentEnabled(meta) {
    const feature = isCommentsFunctionalityEnabled(meta);
    const featureProperty = meta.features.comments.show_toolbar_summary_plans_comments;
    return feature && featureProperty;
}
export function getRealtimeReactionForQuickAccessComments(meta) {
    const feature = isQuickAccessCommentEnabled(meta);
    if (!feature) {
        throw new Error('Trying to get realtime reaction for quick access comments, but the feature is not enabled');
    }
    return meta.features.comments.realtime_reaction_in_seconds;
}
export function isPlansRealtimeFeatureEnabled(meta) {
    return meta.features.plan.realtime.enable;
}
export function getRealtimeReactionForPlans(meta) {
    const feature = isPlansRealtimeFeatureEnabled(meta);
    if (!feature) {
        throw new Error('Trying to get realtime reaction for plans, but the feature is not enabled');
    }
    return meta.features.plan.realtime.reaction_in_seconds;
}
export function getAudiencesQuintileFixedValuesOnSort(meta) {
    if (!isAudiencesQuintileEnabled(meta)) {
        throw new Error('Trying to use a function related to a disabled feature. Audiences Quintile feature is not enabled. Check metadata');
    }
    const audiencesQuintile = meta.features.audiences_quintile;
    if (!audiencesQuintile) {
        throw new Error('Fixed values on sort not found. Check metadata');
    }
    const beginning = audiencesQuintile.audiences.sort.beginning;
    const end = audiencesQuintile.audiences.sort.end;
    return { beginning, end };
}
export function getCartoConfigurationSSO(meta, environment) {
    const cartoOrganization = meta.app_config.carto_organization;
    const isTesting = environment.isTesting();
    if (isTesting) {
        const requiresSSOForTesting = meta.app_config.carto_organization_required_for_testing;
        return requiresSSOForTesting ? cartoOrganization : undefined;
    }
    return cartoOrganization;
}
export function getAccessTokenForSharedPlan(meta, environment) {
    const env = environment.getEnvironmentForMetadata();
    const token = meta.app_config.token_for_shared_plans?.[env];
    return token;
}
