import { SharePlanService } from '@workspaces/services';
import { sendInfoMessageToUser } from '@/helpers/message/message.helper';
import { deserializePlan } from '@/helpers/plan.helper';
import { convertPointsToGeoJsonPoint } from '@/layers/custom-pois/custom-pois.helper';
import { FORMATS } from '@deck.gl/carto/typed';
import { Md5 } from 'ts-md5';
import { isPackagagesFeatureEnabled } from '@/plan/metadata/metadata.helper';
export default {
    async isPasswordRequired(meta, environment, planId) {
        const appVersion = environment.isTesting()
            ? meta.flavour_testing
            : meta.flavour;
        const response = await SharePlanService.isPasswordRequired(meta, environment, planId, appVersion);
        return response;
    },
    async createSharedPlan(meta, environment, planId, password, showPrice = false) {
        const params = {
            planId,
            password: password || undefined,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
        };
        if (isPackagagesFeatureEnabled(meta)) {
            params.package = {
                showPrice,
            };
        }
        const response = await SharePlanService.createSharePlan(meta, environment, params);
        return response;
    },
    async getAssets(meta, environment, planId, password, assetsOffset, token, iv) {
        const params = {
            planId,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
            password: password || undefined,
            assetsOffset: assetsOffset || undefined,
            token: token || undefined,
            iv: iv || undefined,
        };
        try {
            const response = await SharePlanService.getAssets(meta, environment, params);
            return response;
        }
        catch (error) {
            const errorTyped = error;
            sendInfoMessageToUser({
                titleLocaleKey: '',
                msgLocaleKey: '',
                title: 'Error loading plan',
                msg: errorTyped.message,
            });
            console.error(error);
            if (assetsOffset === 0) {
                return { count: 0, assets: [] };
            }
            return [];
        }
    },
    async getPlan(meta, environment, planId, password) {
        const params = {
            planId,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
            password: password || undefined,
        };
        const response = await SharePlanService.getPlan(meta, environment, params);
        const plan = deserializePlan(response);
        return plan;
    },
    async getGeometries(meta, environment, planId, password) {
        const params = {
            planId,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
            password: password || undefined,
        };
        const response = await SharePlanService.getGeometries(meta, environment, params);
        return response;
    },
    async getSharedPlanPoisWithIsochrone(meta, environment, planId, password, filter) {
        const params = {
            planId,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
            password: password || undefined,
            isochronesFilter: filter,
        };
        const response = await SharePlanService.getSharedPlanPoisWithIsochrone(meta, environment, params);
        return response;
    },
    async getSharedActiveCustomPoisGroups(meta, environment, planId, customPoisIds) {
        const params = {
            planId,
            customPoisIds,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
        };
        const response = await SharePlanService.getSharedActiveCustomPoisGroups(meta, environment, params);
        return response;
    },
    async getCustomPOIs(meta, environment, planId, password, activeSubPlanFiltersIndex) {
        const params = {
            planId,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
            password: password || undefined,
        };
        const data = await SharePlanService.getCustomPOIs(meta, environment, params, activeSubPlanFiltersIndex);
        const dataFormatted = convertPointsToGeoJsonPoint(data);
        const dataToReturn = {
            format: FORMATS.GEOJSON,
            data: dataFormatted,
        };
        return dataToReturn;
    },
    async getAssetById(meta, environment, planId, password, assetId) {
        const params = {
            planId,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
            password: password || undefined,
        };
        const data = await SharePlanService.getAssetById(meta, environment, params, assetId);
        return [data];
    },
    async getInsights(meta, environment, planId, password) {
        const params = {
            planId,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
            password: password || undefined,
        };
        const data = await SharePlanService.getInsights(meta, environment, params);
        return data;
    },
    async getSharedPlanContentFromPlanId(meta, environment, planId) {
        const plan = await SharePlanService.getSharedPlanContentFromPlanId(meta, environment, planId);
        return plan;
    },
    async updateSharedPlanContent(meta, environment, sharedPlan) {
        const sharedPlanId = sharedPlan.id;
        if (!sharedPlanId) {
            throw new Error('Shared plan id is missing, and is required for update operation');
        }
        if (sharedPlan.passwordRequire) {
            const password = sharedPlan.password;
            if (!password) {
                throw new Error('Password is required to update shared plan');
            }
            sharedPlan.originalPassword = password;
            sharedPlan.password = Md5.hashStr(password);
        }
        await SharePlanService.updateSharedPlanContent(meta, environment, sharedPlanId, sharedPlan);
    },
    async getSharedPlanAudiencesQuintiles(meta, environment, planId, password) {
        const params = {
            planId,
            appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
            password: password || undefined,
        };
        try {
            const response = await SharePlanService.getSharedPlanAudiencesQuintiles(meta, environment, params);
            return response;
        }
        catch (error) {
            const errorTyped = error;
            sendInfoMessageToUser({
                titleLocaleKey: '',
                msgLocaleKey: '',
                title: 'Error loading plan',
                msg: errorTyped.message,
            });
            console.error(error);
            return [];
        }
    },
};
