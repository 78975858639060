// GENERAL PARAMETERS

export const ENVS = {
  DEV: 'dev',
  STAGING: 'staging',
  PROD: 'production',
}

export const APP_FLAVOURS = {
  US: 'US',
  EU: 'EU',
  FI: 'FI',
}

// BUSINESS PARAMETERS

export const ADMIN_AGENCY = 'rol_nV62MR4qlv9eVEkm' // CCF Admin
export const ADMIN_AGENCY_TEST = 'rol_DTk1F3oihhMYcxJ6' // CCF Admin Test

export const PROXIMITY_TYPES = {
  BRANDS: 'brands',
  CATEGORIES: 'categories',
  CUSTOM_POIS: 'custom',
  LOCATIONS: 'locations',
  TAGS: 'tags',
}

export const GEO_BOUNDARIES_TYPES = {
  US: {},
  EU: {
    COUNTRY: 'Country',
    STATE: 'State',
    NUTS1: 'NUTS1',
    NUTS2: 'NUTS2',
    NUTS3: 'NUTS3',
    LAU: 'LAU',
    CITY: 'City',
    POSTAL_CODE: 'PostalCode',
    AD_REGIONS: 'AdRegion',
    FILE: 'file',
    UNITE_URBANE: 'UniteUrbane',
  },
}

export const GEO_BOUNDARIES_TYPES_PRIORITY = {
  US: {
    [GEO_BOUNDARIES_TYPES.US.COUNTRY]: 8,
    [GEO_BOUNDARIES_TYPES.US.STATE]: 7,
    [GEO_BOUNDARIES_TYPES.US.DMA]: 6,
    [GEO_BOUNDARIES_TYPES.US.CONGRESSIONAL_DISTRICT]: 5,
    [GEO_BOUNDARIES_TYPES.US.CBSA]: 4, // CBSA (Core-based Statistical Area)
    [GEO_BOUNDARIES_TYPES.US.COUNTY]: 3,
    [GEO_BOUNDARIES_TYPES.US.CITY]: 2,
    [GEO_BOUNDARIES_TYPES.US.ZIP_CODE]: 1,
    [GEO_BOUNDARIES_TYPES.US.FILE]: -1,
  },
  EU: {
    [GEO_BOUNDARIES_TYPES.EU.COUNTRY]: 5,
    [GEO_BOUNDARIES_TYPES.EU.STATE]: 5,
    [GEO_BOUNDARIES_TYPES.EU.NUTS1]: 4, // DMA (Designated Market Area)
    [GEO_BOUNDARIES_TYPES.EU.NUTS2]: 3, // Congressional District
    [GEO_BOUNDARIES_TYPES.EU.NUTS3]: 2, // CBSA (Core-based Statistical Area)
    [GEO_BOUNDARIES_TYPES.EU.LAU]: 1, // County
    [GEO_BOUNDARIES_TYPES.EU.CITY]: -1,
    [GEO_BOUNDARIES_TYPES.EU.POSTAL_CODE]: -1,
    [GEO_BOUNDARIES_TYPES.EU.AD_REGIONS]: -1,
    [GEO_BOUNDARIES_TYPES.EU.FILE]: -1,
    [GEO_BOUNDARIES_TYPES.EU.UNITE_URBANE]: -1,
  },
}

export const DISTRIBUTION_FILTER_PARAMS = {
  [GEO_BOUNDARIES_TYPES.COUNTRY]: 'country_max',
  [GEO_BOUNDARIES_TYPES.STATE]: 'state_max',
  [GEO_BOUNDARIES_TYPES.NUTS1]: 'nuts1_max',
  [GEO_BOUNDARIES_TYPES.NUTS2]: 'nuts2_max',
  [GEO_BOUNDARIES_TYPES.NUTS3]: 'nuts3_max',
  [GEO_BOUNDARIES_TYPES.LAU]: 'lau_max',
  [GEO_BOUNDARIES_TYPES.CITY]: 'city_max',
  [GEO_BOUNDARIES_TYPES.POSTAL_CODE]: 'postalcode_max',
  [GEO_BOUNDARIES_TYPES.AD_REGIONS]: 'adregion_max',
  [GEO_BOUNDARIES_TYPES.UNITE_URBANE]: 'unite_urbane_max',
}

export function checkBoundaryType(type) {
  let _type
  if (type === GEO_BOUNDARIES_TYPES.COUNTRY) {
    _type = 'countries'
  } else if (type === GEO_BOUNDARIES_TYPES.STATE) {
    _type = 'states'
  } else if (type === GEO_BOUNDARIES_TYPES.CITY) {
    _type = 'cities'
  } else if (type === GEO_BOUNDARIES_TYPES.LAU) {
    _type = 'laus'
  } else if (type === GEO_BOUNDARIES_TYPES.NUTS1) {
    _type = 'nuts1s'
  } else if (type === GEO_BOUNDARIES_TYPES.NUTS2) {
    _type = 'nuts2s'
  } else if (type === GEO_BOUNDARIES_TYPES.NUTS3) {
    _type = 'nuts3s'
  } else if (type === GEO_BOUNDARIES_TYPES.POSTAL_CODE) {
    _type = 'postal_codes'
  } else if (type === GEO_BOUNDARIES_TYPES.AD_REGIONS) {
    _type = 'adregions'
  } else if (type === GEO_BOUNDARIES_TYPES.UNITE_URBANE) {
    _type = 'unite_urbanes'
  } else if (type === GEO_BOUNDARIES_TYPES.FILE) {
    _type = 'file'
  } else {
    throw new Error('Unknown geo-boundary type')
  }
  return _type
}

export function checkBoundaryTypeReverse(type) {
  let _type
  if (type === 'countries') {
    _type = GEO_BOUNDARIES_TYPES.COUNTRY
  } else if (type === 'states') {
    _type = GEO_BOUNDARIES_TYPES.STATE
  } else if (type === 'cities') {
    _type = GEO_BOUNDARIES_TYPES.CITY
  } else if (type === 'laus') {
    _type = GEO_BOUNDARIES_TYPES.LAU
  } else if (type === 'nuts1s') {
    _type = GEO_BOUNDARIES_TYPES.NUTS1
  } else if (type === 'nuts2s') {
    _type = GEO_BOUNDARIES_TYPES.NUTS2
  } else if (type === 'nuts3s') {
    _type = GEO_BOUNDARIES_TYPES.NUTS3
  } else if (type === 'postal_codes') {
    _type = GEO_BOUNDARIES_TYPES.POSTAL_CODE
  } else if (type === 'adregions') {
    _type = GEO_BOUNDARIES_TYPES.AD_REGIONS
  } else if (type === 'unite_urbane') {
    _type = GEO_BOUNDARIES_TYPES.UNITE_URBANE
  } else if (type === 'file') {
    _type = GEO_BOUNDARIES_TYPES.FILE
  } else {
    throw new Error('Unknown geo-boundary type')
  }
  return _type
}

export function checkDistributonBoundaryType(type) {
  let _type
  if (type === 'Country') {
    _type = 'country_max'
  } else if (type === 'cities') {
    _type = 'cities'
  } else if (type === 'states') {
    _type = 'state_max'
  } else if (type === 'NUTS1') {
    _type = 'nuts1_max'
  } else if (type === 'NUTS2') {
    _type = 'nuts2_max'
  } else if (type === 'NUTS3') {
    _type = 'nuts3_max'
  } else if (type === 'LAU') {
    _type = 'lau_max'
  } else if (type === 'UniteUrbane') {
    _type = 'unite_urbane_max'
  } else {
    throw new Error('Unknown geo-boundary type')
  }
  return _type
}

export const PRODUCTS_TYPES = {
  L1_PANEL_CLASS_DIGITAL: 'l1panelclassDigital',
  L1_PANEL_CLASS_STATIC: 'l1panelclassStatic',
  L1_PANEL_CLASS: 'l1panelclass',
  L2_PRODUCT: 'l2product',
  L3_SUBPRODUCT: 'l3subproduct',
  L4: 'l4',
  L5: 'l5',
  L6: 'l6',
  L7: 'l7',
  L8: 'l8',
  L9: 'l9',
  IS_DIGITAL: 'isDigital',
}

export function getProductType(type) {
  let _type
  if (type === PRODUCTS_TYPES.L1_PANEL_CLASS) {
    _type = PRODUCTS_TYPES.L1_PANEL_CLASS
  } else if (type === PRODUCTS_TYPES.L2_PRODUCT) {
    _type = PRODUCTS_TYPES.L2_PRODUCT
  } else if (type === PRODUCTS_TYPES.L3_SUBPRODUCT) {
    _type = PRODUCTS_TYPES.L3_SUBPRODUCT
  } else if (type === PRODUCTS_TYPES.L4) {
    _type = PRODUCTS_TYPES.L4
  } else if (type === PRODUCTS_TYPES.L5) {
    _type = PRODUCTS_TYPES.L5
  } else if (type === PRODUCTS_TYPES.L6) {
    _type = PRODUCTS_TYPES.L6
  } else if (type === PRODUCTS_TYPES.L7) {
    _type = PRODUCTS_TYPES.L7
  } else if (type === PRODUCTS_TYPES.L8) {
    _type = PRODUCTS_TYPES.L8
  } else if (type === PRODUCTS_TYPES.L9) {
    _type = PRODUCTS_TYPES.L9
  } else if (type === PRODUCTS_TYPES.IS_DIGITAL) {
    _type = PRODUCTS_TYPES.IS_DIGITAL
  } else {
    throw new Error('Unknown product type')
  }
  return _type
}

export const I18N_PANEL_TYPES = {
  default: {
    L1_PANEL_CLASS: 'l1panelclass',
    L2_PRODUCT: 'l2product',
    L3_SUBPRODUCT: 'l3subproduct',
    L4: 'l4subproduct',
    L5: 'l5subproduct',
    L6: 'l6subproduct',
    L7: 'l7subproduct',
    L8: 'l8subproduct',
    L9: 'l9subproduct',
  },
  239: {
    // Norwey
    L1_PANEL_CLASS: 'Environment',
    L2_PRODUCT: 'Environment sub-type',
    L3_SUBPRODUCT: 'Proposed Package Group 3',
    L4: 'Proposed Package Main',
    L5: 'Format',
    L6: 'Installation Type',
    L7: 'Plan No.',
    L8: 'Panel Type',
    L9: 'Revenue Branch',
  },
  37: {
    // Denmark
    L1_PANEL_CLASS: 'Environment',
    L2_PRODUCT: 'Environment sub-type',
    L3_SUBPRODUCT: 'Proposed Package Group 3',
    L4: 'Proposed Package Main',
    L5: 'Format',
    L6: 'Installation Type',
    L7: 'Plan No.',
    L8: 'Panel Type',
    L9: 'Revenue Branch',
  },
  198: {
    // Sweden
    L1_PANEL_CLASS: 'Environment',
    L2_PRODUCT: 'Environment sub-type',
    L3_SUBPRODUCT: 'Proposed Package Group 3',
    L4: 'Proposed Package Main',
    L5: 'Format',
    L6: 'Installation Type',
    L7: 'Plan No.',
    L8: 'Panel Type',
    L9: 'Revenue Branch',
  },
  61: {
    // France
    L1_PANEL_CLASS: 'Environnement',
    L2_PRODUCT: "Sous-type d'environnement",
    L3_SUBPRODUCT: 'Univers Communication',
    L4: 'Support Communication',
    L5: 'Type Panel',
    L6: 'Nb de faces réels',
    L7: 'Univers Contractuel',
    L8: 'Region CCF',
    L9: 'Format',
  },
}

export const AUDIENCES_CONFIG = {
  index: 100,
  percent: 20,
  maxElementsPerGroup: 10,
}

export const AUDIENCES_TYPE = {
  INDEX: 'index',
  PERCENT: 'percent',
}

export const OPTIMIZE_BY_TYPES = {
  IMPRESSIONS: 'impressions',
  AUDIENCE: 'audience',
}

export const getRedirectUri = () => {
  return `${window.location.origin}/login`
}

export const getRequireComponent = () => {
  return require.context(
    './components/common/global',
    true,
    /[A-Z]\w+\.(vue|js)$/,
  )
}

export const SessionStorageKeys = {
  PreviousRoute: 'prvdhneiuheiu',
  DirtyPlanFilters: 'dpfiuher223giu3erwh',
}
